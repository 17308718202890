import React from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'

import { ArticleLocaleAccessor } from 'accessors'

import state from 'state'

import { useCallback, useMemo, useTranslate } from 'hooks'

import Select from 'react-select/creatable'

import './tags.scss'

const block = 'edit-tags'
const cx = classNames(block)

const EditTags = ({
  tags, articleLocaleId, readOnly,
} : {
  tags: string[], articleLocaleId: string, readOnly: boolean,
}) => {
  const Strings = useTranslate('pages.connected.edit.tags')

  const { add, list } = state.tags

  const value = useMemo(() => list
    .filter((t) => tags.includes(t._id))
    .map((t) => ({
      label: t.text,
      value: t._id,
    }))
    .sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }

      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }

      return 0
    }), [list, tags])

  const options = useMemo(() => list
    .map((t) => ({ label: t.text, value: t._id }))
    .sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }

      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      }

      return 0
    }), [list])

  const onChange = useCallback((newValue : { label: string, value: string }[] | undefined | null) => {
    const nextTags = (newValue || []).map((v) => v.value)
    ArticleLocaleAccessor.mutate
      .update
      .withId(articleLocaleId)
      .withRecord({
        tags: nextTags,
      })
      .then(async () => {})
  }, [articleLocaleId])

  const onCreateOption = useCallback((option : string) => {
    add(option)
      .then(async (newTag: any) => {
        onChange([...value, { value: newTag._id, label: newTag.text }])
      })
  }, [onChange, value])

  return (
    <div className={cx(block, { '--readonly': readOnly })}>
      <Select
        classNamePrefix={block}
        isMulti
        placeholder={Strings.placeholder}
        value={value}
        options={options}
        onCreateOption={onCreateOption}
        onChange={onChange as any}
      />
    </div>
  )
}

export default observer(EditTags)
