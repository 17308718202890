import React, { useCallback } from 'react'
import { observer } from 'mobx-react'

import Configuration from 'configuration'

import { classNames } from 'utils/react'

import { ArticleLocaleAccessor } from 'accessors'

import { useTranslate, useAlertController } from 'hooks'

import { Alert } from 'components/shared/semantics'

import './image.scss'
import state from 'state'


const block = 'edit-image'
const cx = classNames(block)

const EditImage = ({
  image, articleLocaleId, readOnly,
} : {
  image?: string, articleLocaleId: string, readOnly: boolean
}) => {
  const Strings = useTranslate('pages.connected.edit.image')

  const pick = useAlertController()

  const select = useCallback((i) => () => {
    ArticleLocaleAccessor.mutate
      .update
      .withId(articleLocaleId)
      .withRecord({
        image: i,
      })
      .then(async () => {})
  }, [articleLocaleId])

  return (
    <button
      type="button"
      className={cx(block)}
      style={{
        backgroundImage: image
          && `url(${encodeURI(`${image.startsWith('http') ? image : `${Configuration.images}/${image}`}`)})`,
      }}
      onClick={readOnly ? undefined : pick.open}
    >
      <div className={cx('__pick')}>
        {!image && Strings.placeholder}
      </div>

      <Alert
        customClass={cx('__alert')}
        register={pick.register}
        onCancel={pick.close}
        onConfirm={pick.close}
      >
        <span className={cx('__title')}>
          {Strings.title}
        </span>
        <div className={cx('__list')}>
          {
            state.images.list
              .map((i) => (
                <button
                  aria-label={i.file}
                  className={cx('__image', { '__image--selected': image === i.file })}
                  type="button"
                  key={i._id}
                  style={{ backgroundImage: `url(${encodeURI(`${Configuration.images}/${i.file}`)})` }}
                  onClick={select(i._id)}
                />
              ))
          }
        </div>
      </Alert>
    </button>
  )
}

export default observer(EditImage)
