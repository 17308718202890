import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'

import state from 'state'

import { ArticleAccessor, ArticleLocaleAccessor } from 'accessors'

import { useAlertController, useLocalStore, useTranslate } from 'hooks'

import Select from 'react-select'
import { Alert } from 'components/shared/semantics'

import './share.scss'

const locales = ['en', 'fr']
const flags : {
  [key: string]: string,
} = {
  fr: 'flag-icon flag-icon-fr',
  en: 'flag-icon flag-icon-gb',
}

const block = 'share'
const cx = classNames(block)

const Share = observer(({
  alertState, article,
} : {
  alertState: ReturnType<typeof useAlertController>, article?: string
}) => {
  const Strings = useTranslate('pages.connected.edit.share')

  const translations = useLocalStore<{
    article?: string,
    owner?: string,
    loadedOwner?: string,
    list: { locale: string, exists: boolean, translator: string }[]
    update(): Promise<void>,
    updateOwner(): void,
      }>((current) => ({
        owner: undefined,
        list: [],
        async update() {
          const translators : {[key:string]: string} = {}
          translations.list.forEach((translation) => {
            translators[translation.locale] = translation.translator
          })

          // Create attributed locales
          await Promise.all(translations.list.map(async (translation) => {
            if (translation.exists || translation.translator === state.session.account?._id) {
              return
            }

            const { locale } = translation

            // Create the corresponding locale
            const articleLocale: { _id: string } = await ArticleLocaleAccessor.mutate
              .create
              .withRecord({
                title: '',
              })
              .select({
                _id: true,
              }) as any

            // Update the article
            await ArticleAccessor.mutate
              .update
              .withId(current.article)
              .withRecord({
                content: {
                  locale: {
                    [locale]: articleLocale._id,
                  },
                },
              })
          }))

          await ArticleAccessor.mutate
            .update
            .withId(current.article)
            .withRecord({
              translators,
            })
        },
        updateOwner() {
          ArticleAccessor.mutate
            .update
            .withId(current.article)
            .withRecord({
              owner: translations.owner,
            })
            .then(async () => {})
        },
      }), { article })

  useEffect(() => {
    const run = async () => {
      const articleDb : {
        owner: {
          _id: string
        },
        translators?: {
          map?: {
            keys?: string[],
            values?: string[],
          }
        },
        content: {
          locale?: {
            map?: {
              keys?: string[],
              values?: string[],
            }
          }
        },
      } = await ArticleAccessor.query
        .read
        .where({ _id: article })
        .select({
          _id: true,
          owner: {
            _id: true,
          },
          content: {
            locale: {
              map: {
                keys: true,
              },
            },
          },
          translators: {
            map: {
              keys: true,
              values: true,
            },
          },
        })

      translations.list = locales.map((locale) => {
        const translatorIndex = (articleDb.translators?.map?.keys || []).indexOf(locale)
        return ({
          locale,
          exists: (articleDb.content?.locale?.map?.keys || []).includes(locale),
          translator: (articleDb.translators?.map?.values?.[translatorIndex]) || state.session.account!._id,
        })
      })
      translations.owner = articleDb.owner?._id
      translations.loadedOwner = articleDb.owner?._id
    }

    if (article) {
      run()
    }
  }, [article])

  const translatorsOptions = state.editors.list.map((e) => ({
    label: e.name,
    value: e._id,
  }))

  const isOwner = translations.loadedOwner === state.session.account?._id
  const owner = (state.editors.list.find((e) => e._id === translations.owner) || { name: '', _id: null })

  return (
    <Alert
      customClass={cx(block)}
      register={alertState.register}
      onConfirm={alertState.close}
      onCancel={alertState.close}
    >
      <div className={cx('__title')}>
        {Strings.title}
      </div>
      <div className={cx('__owner')}>
        <div className={cx('__owner-label')}>
          {Strings.author}
        </div>
        <div className={cx('__owner-name')}>
          {
            isOwner ? (
              <Select
                options={translatorsOptions}
                value={{
                  label: owner.name,
                  value: owner._id,
                }}
                menuPosition="fixed"
                onChange={(next) => {
                  if (next && 'value' in next) {
                    // eslint-disable-next-line no-param-reassign
                    translations.owner = next.value!
                  }

                  translations.updateOwner()
                }}
              />
            ) : (
              owner.name
            )
          }
        </div>
      </div>

      <div className={cx('__translators')}>
        {Strings.translators}
      </div>
      <div className={cx('__locales')}>
        {
          translations.list.map((translation) => {
            const translator = state.editors.list
              .find((e) => e._id === translation.translator) || { name: '', _id: null }

            return (
              <div className={cx('__locale')}>
                <div className={cx('__locale-flag')}>
                  <span className={flags[translation.locale]} />
                </div>
                <div className={cx('__locale-translator')}>
                  {isOwner ? (
                    <Select
                      options={translatorsOptions}
                      value={{
                        label: translator.name,
                        value: translator._id,
                      }}
                      menuPosition="fixed"
                      onChange={(next) => {
                        if (next && 'value' in next) {
                          // eslint-disable-next-line no-param-reassign
                          translation.translator = next.value!
                        }

                        translations.update()
                      }}
                    />
                  ) : (
                    translator.name
                  )}
                </div>
              </div>
            )
          })
        }
      </div>
    </Alert>
  )
})

export default Share
