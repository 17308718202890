import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'

import {
  useLocalStore, useCallback, useRef,
} from 'hooks'

import './floating-toolbar.scss'

const block = 'edit-floating-toolbar'
const cx = classNames(block)

const useToolbarClick = (toggle : Function, key : string, ...args: any[]) => {
  const clicked = useRef(false)
  const onMouseDown = useCallback((e) => {
    e.preventDefault()
    clicked.current = true
  }, [clicked])
  const onMouseUp = useCallback((e) => {
    e.preventDefault()
    if (clicked.current) {
      clicked.current = false
      toggle(key, ...args)
    }
  }, [clicked, toggle, key, ...args])

  return {
    onMouseDown,
    onMouseUp,
  }
}

const isForward = (selection : Selection) => {
  if (!selection.anchorNode || !selection.focusNode) {
    return false
  }

  const position = selection.anchorNode.compareDocumentPosition(selection.focusNode)

  return !(
    // position == 0 if nodes are the same
    (!position && selection.anchorOffset > selection.focusOffset)
    || position === Node.DOCUMENT_POSITION_PRECEDING
  )
}

const useToolbarUpdate = () => {
  const opacityTimeout = useRef<number|null>(null)
  const toolbar = useLocalStore(() => ({
    top: '0',
    left: '0',
    opacity: 0,
    pointerEvents: 'none' as 'none'|'all',
    updatePosition() {
      const native = window.getSelection()

      if (opacityTimeout.current) {
        window.clearTimeout(opacityTimeout.current)
      }

      if (native && !native.isCollapsed) {
        const range = native.getRangeAt(0)
        const contrivedRange = new Range()
        const forward = isForward(native)
        contrivedRange.setStart(
          !forward
            ? range.startContainer
            : range.endContainer,
          !forward
            ? range.startOffset
            : range.endOffset,
        )
        contrivedRange.setEnd(contrivedRange.startContainer, contrivedRange.startOffset)
        const rect = contrivedRange.getBoundingClientRect()

        toolbar.top = `${window.pageYOffset + (rect.top - 40)}px`
        toolbar.left = forward
          ? `${window.pageXOffset + (rect.left + rect.width - 144)}px`
          : `${window.pageXOffset + (rect.left - 16)}px`
        opacityTimeout.current = window.setTimeout(() => {
          toolbar.opacity = 1
          toolbar.pointerEvents = 'all'
        }, 200)
      } else {
        opacityTimeout.current = window.setTimeout(() => {
          toolbar.opacity = 0
          toolbar.pointerEvents = 'none'
        }, 100)
      }
    },
  }))

  useEffect(() => {
    window.addEventListener('scroll', toolbar.updatePosition)
    window.addEventListener('select', toolbar.updatePosition)
    window.addEventListener('resize', toolbar.updatePosition)

    return () => {
      window.removeEventListener('scroll', toolbar.updatePosition)
      window.removeEventListener('select', toolbar.updatePosition)
      window.removeEventListener('resize', toolbar.updatePosition)
    }
  }, [])

  useEffect(toolbar.updatePosition)

  return { ...toolbar }
}

const EditFloatingToolbar = ({
  toggleColor, isMarkActive,
} : {
  toggleColor: Function, isMarkActive: Function,
}) => {
  const toolbar = useToolbarUpdate()

  return (
    <div className={cx(block)} style={toolbar}>
      {
        ['pink', 'orange', 'blue', 'green', 'purple']
          .map((color) => (
            <button
              key={color}
              type="button"
              className={cx('__button')}
              {...useToolbarClick(toggleColor, color)}
            >
              <div className={cx('__mark', `__mark--${color}`, { '__mark--active': isMarkActive(color) })} />
            </button>
          ))
      }
    </div>
  )
}

export default observer(EditFloatingToolbar)
