import React, { useCallback } from 'react'
import { observer } from 'mobx-react'

import Configuration from 'configuration'
import { Block, Editor } from 'slate'

import { classNames } from 'utils/react'

import { useTranslate, useAlertController } from 'hooks'

import Markdown from 'react-markdown'
import { Alert } from 'components/shared/semantics'

import './inline-image.scss'
import state from 'state'


const block = 'edit-inline-image'
const cx = classNames(block)

const EditImage = ({
  node, editor, locale,
} : {
  node: Block, editor: Editor, locale: string, isFocused: boolean
}) => {
  const Strings = useTranslate('pages.connected.edit.inlineImage')

  const pick = useAlertController()

  const select = useCallback((i) => () => {
    editor.setNodeByKey(node.key, {
      type: 'image',
      data: {
        src: encodeURI(
          `${i.file.startsWith('http')
            ? i.file
            : `${Configuration.images}/${i.file}`}`,
        ),
        alt: i.caption?.en || i.caption?.fr,
        caption: i.caption,
      },
    })

    pick.close()
  }, [node, pick.close])

  const image = node.data.get('src')
  const caption = node.data.get('caption')

  return (
    <>
      <button
        type="button"
        className={cx(block)}
        onClick={pick.open}
      >
        {
        image ? (
          <>
            <img
              className={cx('__preview')}
              alt=""
              src={image}
            />
          </>
        ) : (
          <div className={cx('__pick')}>
            {Strings.placeholder}
          </div>
        )
      }

        <Alert
          customClass={cx('__alert')}
          register={pick.register}
          onCancel={pick.close}
          onConfirm={pick.close}
        >
          <span className={cx('__title')}>
            {Strings.title}
          </span>
          <div className={cx('__list')}>
            {
            state.images.inlines
              .map((i) => (
                <button
                  aria-label={i.file}
                  className={cx('__image', { '__image--selected': image === i.file })}
                  type="button"
                  key={i._id}
                  style={{ backgroundImage: `url(${encodeURI(`${Configuration.images}/${i.file}`)})` }}
                  onClick={select(i)}
                />
              ))
          }
          </div>
        </Alert>
      </button>
      {caption && (
        <Markdown className={cx('__caption')}>{caption[locale] || caption.fr || caption.en || ''}</Markdown>
      )}
    </>
  )
}

export default observer(EditImage)
