import React from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'
import { stopPropagation, queryString, toast } from 'utils/misc'
import { navigate } from 'utils/router'

import state from 'state'

import {
  Client,
  ArticleAccessor, ArticleLocaleAccessor, ArticlePublicationAccessor, DeployAccessor,
} from 'accessors'

import {
  useTranslate, useLocalStore, useCallback, useRef, useEffect, useState, useMemo,
} from 'hooks'

import SEO from 'components/shared/seo'
import {
  FaCheck, FaStar, FaArrowLeft, FaToggleOn, FaArrowAltCircleUp,
} from 'components/shared/icons'
import { Link } from 'components/shared/semantics'
import { Editor, Textarea } from 'components/shared/inputs'

import EditToolbar from './edit/toolbar'
import EditFloatingToolbar from './edit/floating-toolbar'
import EditTags from './edit/tags'
import EditImage from './edit/image'
import EditInlineImage from './edit/inline-image'

import './edit.scss'

const block = 'page-edit'
const cx = classNames(block)

type Article = {
  _id: string,
  topics: {[key: string]: boolean},
  owner: string,
  translator: string,
  locale: {
    _id: string,
    title: string,
    content: any,
    introduction: any,
    tags: string[],
    image: {
      _id: string,
      file: string,
    },
    canEdit: boolean,
  },
  publication: {
    _id: string,
  },
  locales: string[],
  publications: string[],
  disabledPublications: string[],
}

type Data = {
  article: null|Article,
  published: boolean,
  disabled: boolean,

  update: (arg: Record<string, any>) => Promise<void>,
  load: (locale: string, _id: string) => void,

  locale: string,
  saving: boolean,

  changeLocale: (locale: string) => void,

  onValueChange?: (value: any) => void
  onIntroductionChange?: (value: any) => void
  onTitleChange?: (title: React.ChangeEvent<HTMLTextAreaElement>) => void

  debugValue?: any,
}

type DebounceCallback = (
  callback: (...args: any) => void,
  init: (...args: any) => boolean,
  debounce: number
) => ((...args: any) => void)

const topics = ['action', 'art', 'how-to', 'misc', 'professional', 'story']

const useDebounceCallback : DebounceCallback = (callback, init, debounce) => {
  const debounceTimeout = useRef<number>()
  const debounceArgs = useRef<any>()

  const debouncedCallback = useCallback(() => {
    callback(...(debounceArgs.current || []))
  }, [callback])

  return useCallback((...args: any) => {
    if (debounceTimeout.current) {
      window.clearTimeout(debounceTimeout.current)
    }

    const shouldRun = init(...args)

    if (shouldRun) {
      debounceArgs.current = args
      debounceTimeout.current = window.setTimeout(debouncedCallback, debounce)
    }
  }, [debouncedCallback, debounce, init])
}

const useChangeCallback = (
  change: (value: any) => void,
  active: (b: boolean) => void,
  initialBounce: boolean = true,
  deps: any[],
) => {
  const lastValue = useRef<any>(null)

  const dataChangeCallback = useCallback(async (value: any) => {
    change(value)
    active(false)
  }, deps)

  const dataChangeInit = useCallback((value) => {
    const stringified = JSON.stringify(value)
    const last = lastValue.current
    lastValue.current = stringified

    if (last === stringified || (initialBounce && !last)) {
      return false
    }

    active(true)
    return true
  }, deps)

  return useDebounceCallback(dataChangeCallback, dataChangeInit, 1000)
}

const useDataStore = (search: string) => {
  const subscription = useRef<any>(null)

  const data = useLocalStore<Data>(() => ({
    article: null,
    get published() : boolean {
      return !!data.article && data.article.publications.includes(data.locale)
    },
    get disabled() : boolean {
      return !!data.article && data.article.disabledPublications.includes(data.locale)
    },
    async update(article : Record<string, any>) {
      data.article = {
        _id: article._id,
        owner: article.owner._id,
        translator: article.owner._id,
        topics: topics.reduce((ts, t) => ({
          ...ts,
          [t]: (article.topics || []).includes(t),
        }), {}),
        locale: article.content.locale && article.content.locale[data.locale],
        publication:
          (
            article.content.publication
            && article.content.publication[data.locale]
          )
          || (
            article.content.disabledPublication
            && article.content.disabledPublication[data.locale]
          ),
        publications: article.content.publication ? article.content.publication.map.keys : [],
        disabledPublications: article.content.disabledPublication ? article.content.disabledPublication.map.keys : [],
        locales: article.content.locale ? article.content.locale.map.keys : [],
      }

      if (article.translators) {
        const localeIndex = article.translators.map.keys.indexOf(data.locale)
        if (localeIndex > -1) {
          data.article!.translator = article.translators.map.values[localeIndex]
        }
      }

      data.article!.locale.canEdit = article.canEdit[data.locale]
    },

    locale: '',
    saving: false,

    async changeLocale(locale : string) {
      // Check if the locale exists for the given article
      if (!data.article!.locales.includes(locale)) {
        // Create the corresponding locale
        const articleLocale: { _id: string } = await ArticleLocaleAccessor.mutate
          .create
          .withRecord({
            title: '',
          })
          .select({
            _id: true,
          }) as any

        // Update the article
        await ArticleAccessor.mutate
          .update
          .withId(data.article!._id)
          .withRecord({
            content: {
              locale: {
                [locale]: articleLocale._id,
              },
            },
            translators: {
              [locale]: state.session.account?._id,
            },
          })
      }

      navigate(`/edit?article=${data.article!._id}&locale=${locale}`)
      data.load(locale, data.article!._id)
    },
    load(locale: string, _id: string) {
      if (subscription.current) {
        subscription.current.unsubscribe(data.update)
      }

      data.locale = locale
      subscription.current = ArticleAccessor.query
        .get
        .where({
          _id,
        })
        .select({
          _id: true,
          topics: true,
          canEdit: {
            [locale]: true,
          },
          owner: {
            _id: true,
          },
          translators: {
            map: {
              keys: true,
              values: true,
            },
          },
          // @ts-ignore
          content: {
            locale: {
              [locale]: {
                _id: true,
                title: true,
                content: true,
                introduction: true,
                readingTime: true,
                tags: true,
                image: {
                  _id: true,
                  file: true,
                },
              },
              map: {
                keys: true,
              },
            },
            publication: {
              [locale]: {
                _id: true,
              },
              map: {
                keys: true,
              },
            },
            disabledPublication: {
              [locale]: {
                _id: true,
              },
              map: {
                keys: true,
              },
            },
          },
        })
        .listen('ArticleLocale') // Listen to locale change event
        .subscribe(data.update)
    },
  }))

  useEffect(() => {
    const { article: _id, locale } = queryString.parse(search) || {}

    data.load(locale as string, _id as string)

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe()
      }
    }
  }, [])

  const onValueChangeCallback = useChangeCallback(
    async (value) => {
      if (data.article) {
        await ArticleLocaleAccessor.mutate
          .update
          .withId(data.article.locale._id)
          .withRecord({
            content: value,
            updated: new Date().toISOString(),
          })
      }
    }, (saving) => {
      data.saving = saving
    }, true,
    [data.article, data.article?.locale?._id],
  )

  const onIntroductionChangeCallback = useChangeCallback(
    async (value) => {
      if (data.article) {
        await ArticleLocaleAccessor.mutate
          .update
          .withId(data.article.locale._id)
          .withRecord({
            introduction: value,
            updated: new Date().toISOString(),
          })
      }
    }, (saving) => {
      data.saving = saving
    },
    true,
    [data.article, data.article?.locale?._id],
  )

  const onTitleChangeCallback = useChangeCallback(
    async (value) => {
      if (data.article) {
        await ArticleLocaleAccessor.mutate
          .update
          .withId(data.article.locale._id)
          .withRecord({
            title: value,
            updated: new Date().toISOString(),
          })
      }
    }, (saving) => {
      data.saving = saving
    },
    false,
    [data.article, data.article?.locale?._id],
  )

  data.onValueChange = useCallback(
    (value: any) => {
      data.debugValue = value.toJSON()
      onValueChangeCallback(value.toJSON())
    },
    [onValueChangeCallback, data.article, data.article?.locale?._id],
  )
  data.onIntroductionChange = useCallback(
    (value: any) => onIntroductionChangeCallback(value.toJSON()),
    [onIntroductionChangeCallback, data.article, data.article?.locale?._id],
  )
  data.onTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      data.article!.locale.title = event.target.value
      onTitleChangeCallback(event.target.value)
    },
    [onTitleChangeCallback, data.article, data.article?.locale?._id],
  )

  return data
}

const PageEdit = ({ location } : { location: { search: string } }) => {
  const Strings = useTranslate('pages.connected.edit')

  const data = useDataStore(location.search)

  const [commands, registerCommands] = useState({
    isMarkActive: () => false,
    isBlockActive: () => false,
    isInlineActive: () => false,
    toggleMark: () => false,
    toggleBlock: () => false,
    toggleInline: () => false,
    toggleColor: () => false,
    isInlineAvailable: () => false,
    getInlineData: () => ({}),
    setInlineData: () => false,
  })

  const onTopicClick = useCallback((topic) => {
    data.article!.topics[topic] = !data.article!.topics[topic]
    ArticleAccessor.mutate
      .update
      .withId(data.article!._id)
      .withRecord({
        topics: Object.keys(data.article!.topics).filter((t) => data.article!.topics[t]),
      })
      .then(async () => {})
  }, [data.article, data.article?.locale?._id])

  const onTopicClicks = useMemo(() => {
    const clicks : any = {}
    topics.forEach((t) => {
      clicks[t] = () => onTopicClick(t)
    })
    return clicks
  }, [onTopicClick])

  const onPublish = useCallback(() => {
    Client.builder
      .withName('articleIndex')
      .withArgs({
        article: data.article!._id,
      })
      .asQuery()
      .catch(() => {
        toast.clear()
        toast.error(Strings.publish.index.error)
      })
  }, [data, data.article, data.article?.locale?._id])

  const onTogglePublish = useCallback(async () => {
    if (data.published || data.disabled) {
      // If the article had already been published once, toggle it
      const { published } = data

      const toSet = published ? 'disabledPublication' : 'publication'
      const toUnset = published ? 'publication' : 'disabledPublication'

      await ArticleAccessor.mutate
        .update
        .withId(data.article!._id)
        .withRecord({
          content: {
            [toSet]: { [data.locale]: data.article!.publication._id },
            [toUnset]: { [data.locale]: null },
          },
        })
        .then(async () => {
          if (!published) {
            toast.clear()
            toast.success(Strings.publish.toggle.success)
          } else {
            toast.clear()
            toast.info(Strings.publish.toggle.down)
          }
        })
        .catch(() => toast.error(Strings.publish.toggle.error))
    } else {
      if (data.article!.locale.image?._id === data.article!.locale.image?.file) {
        toast.clear()
        toast.error('L\'image de l\'article est encore à l\'ancien format')
        return
      }

      // Else, create a new publication based on current content
      const publication : any = await ArticlePublicationAccessor.mutate
        .create
        .withRecord({
          title: data.article!.locale.title,
          content: data.article!.locale.content,
          introduction: data.article!.locale.introduction,
          image: data.article!.locale.image._id,
          tags: data.article!.locale.tags,
          author: data.article!.owner,
          translator: data.article!.translator,
          published: {
            date: {
              initial: new Date().toISOString(),
            },
          },
        })
        .then(async (pub) => {
          toast.clear()
          toast.success(Strings.publish.toggle.success)
          return pub
        })
        .catch(() => {
          toast.clear()
          toast.error(Strings.publish.toggle.error)
        })

      if (publication) {
        await ArticleAccessor.mutate
          .update
          .withId(data.article!._id)
          .withRecord({
            content: {
              publication: {
                [data.locale]: publication._id,
              },
            },
          })

        onPublish()
      }
    }

    await DeployAccessor.mutate
      .create
      .withRecord({
        type: 'publish',
        date: new Date().toISOString(),
      })
  }, [data, data.article, data.article?.locale?._id])

  const onUpdatePublish = useCallback(async () => {
    if (!data.article?.publication?._id) {
      toast.clear()
      toast.error('Error happened while updating article publication: publication not found')
      return
    }

    if (data.article!.locale.image?._id === data.article!.locale.image?.file) {
      toast.clear()
      toast.error('L\'image de l\'article est encore à l\'ancier format')
      return
    }

    const content: any = {
      title: data.article!.locale.title,
      content: data.article!.locale.content,
      introduction: data.article!.locale.introduction,
      tags: data.article!.locale.tags,
      author: data.article!.owner,
      translator: data.article!.translator,
      published: {
        date: {
          updated: new Date().toISOString(),
        },
      },
    }

    if (data.article!.locale.image._id) {
      content.image = data.article!.locale.image._id
    }

    await ArticlePublicationAccessor.mutate
      .update
      .withId(data.article!.publication._id)
      .withRecord(content)
      .then(async () => toast.success(Strings.publish.update.success))
      .catch(() => toast.error(Strings.publish.update.error))

    onPublish()

    await DeployAccessor.mutate
      .create
      .withRecord({
        type: 'publish',
        date: new Date().toISOString(),
      })
  }, [data.article, data.article?.locale?._id])

  const isOwner = !!state.session.account?._id && data.article?.owner === state.session.account?._id

  return (
    <div className={cx(block)}>
      <SEO title={Strings.title} />

      <Link to="/" className={cx('__back')}>
        <FaArrowLeft />
      </Link>

      <div className={cx('__saving')}>
        {
          data.saving
            ? <FaStar className={cx('__icon-saving')} title={Strings.saving} />
            : <FaCheck className={cx('__icon-saved')} title={Strings.saved} />
        }
      </div>

      <div className={cx('__published')}>
        {
          data.article?.locale.canEdit && (
            <>
              <button
                className={cx('__published-main')}
                type="button"
                onClick={onTogglePublish}
              >
                <FaToggleOn className={cx('__published-toggle', { '__published-toggle--active': data.published })} />
                <span className={cx('__published-text')}>
                  {Strings.published[`${data.published}`]}
                </span>
              </button>
              <button
                onClick={stopPropagation(onUpdatePublish)}
                type="button"
                className={cx('__published-update', { '__published-update--active': data.published })}
              >
                <FaArrowAltCircleUp />
              </button>
            </>
          )
        }
      </div>

      <EditToolbar
        {...commands}
        locale={data.locale}
        activeLocales={isOwner ? [] : data.article?.locales}
        changeLocale={data.changeLocale}
        article={data.article?._id}
      />
      <EditFloatingToolbar {...commands} />

      {
        data.article && (
          <div className={cx('__article')}>
            <div className={cx('__topics')}>
              {
                topics.map((topic) => (
                  <button
                    type="button"
                    key={topic}
                    className={cx('__topic', { '__topic--active': data.article!.topics[topic] })}
                    onClick={isOwner ? onTopicClicks[topic] : undefined}
                  >
                    {Strings.topics[topic]}
                  </button>
                ))
              }
            </div>

            <EditTags
              readOnly={!data.article.locale.canEdit}
              tags={data.article!.locale.tags}
              articleLocaleId={data.article!.locale._id}
            />

            <Textarea
              readOnly={!data.article.locale.canEdit}
              className={cx('__title')}
              value={data.article!.locale.title}
              onChange={data.onTitleChange}
              placeholder={Strings.titlePlaceholder}
            />

            <div className={cx('__info')}>
              <EditImage
                readOnly={!data.article.locale.canEdit}
                image={data.article!.locale.image?.file}
                articleLocaleId={data.article!.locale._id}
              />
              <div className={cx('__introduction')}>
                <Editor
                  simple
                  locale={data.locale}
                  imagePicker={EditInlineImage}
                  readOnly={!data.article.locale.canEdit}
                  valueId={data.article!.locale._id}
                  initialValue={data.article!.locale.introduction}
                  onValueChange={data.onIntroductionChange!}
                  placeholder={Strings.introductionPlaceholder}
                />
              </div>
            </div>

            <Editor
              locale={data.locale}
              imagePicker={EditInlineImage}
              readOnly={!data.article.locale.canEdit}
              valueId={data.article!.locale._id}
              initialValue={data.article!.locale.content}
              onValueChange={data.onValueChange!}
              placeholder={Strings.articlePlaceholder}
              registerCommands={registerCommands}
            />
          </div>
        )
      }
    </div>
  )
}

export default observer(PageEdit)
