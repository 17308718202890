// React
import React, { useCallback } from 'react'
import { observer } from 'mobx-react'

// Utils
import { classNames } from 'utils/react'

// Hooks
import { useLocalStore } from 'hooks'

// Components
import { OutsideClickHandler } from 'components/shared/inputs'
import { FaChevronDown } from 'components/shared/icons'

// CSS
import './language-dropdown.scss'


const block = 'language-dropdown'
const cx = classNames(block)

const flags : {
  [key: string]: string,
} = {
  fr: 'flag-icon flag-icon-fr',
  en: 'flag-icon flag-icon-gb',
}
const languages : {
  [key: string]: string,
} = {
  fr: 'Français',
  en: 'English',
}

const LanguageDropdown = ({
  language, onLanguageSelect, activeLanguages, full = false,
} : {
  language: string,
  onLanguageSelect: (lang: string) => void,
  activeLanguages: string[],
  full?: boolean,
}) => {
  const languageDropdown = useLocalStore(() => ({
    open: false,
    toggle() {
      languageDropdown.open = !languageDropdown.open
    },
    close() {
      languageDropdown.open = false
    },
  }))

  const selectLanguage = useCallback((lang) => (e: React.MouseEvent) => {
    e.preventDefault()
    onLanguageSelect(lang)
  }, [onLanguageSelect])

  return (
    <OutsideClickHandler
      onOutsideClick={languageDropdown.close}
    >
      <button
        onClick={languageDropdown.toggle}
        type="button"
        className={cx('__button')}
      >
        <div className={flags[language]} key={language} />
        {full && <span>{languages[language]}</span>}
        <FaChevronDown className={cx('__chevron')} />

        <div className={cx('__dropdown', { '__dropdown--open': languageDropdown.open })}>
          {
            ['fr', 'en']
              .filter((lang) => activeLanguages.length < 1 || activeLanguages.includes(lang))
              .map((lang) => (
                <a
                  href="./"
                  type="button"
                  key={lang}
                  onClick={selectLanguage(lang)}
                  className={cx('__language')}
                >
                  <span className={flags[lang]} />
                  {languages[lang]}
                </a>
              ))
          }
        </div>
      </button>
    </OutsideClickHandler>
  )
}

export default observer(LanguageDropdown)
