import React from 'react'
import { observer } from 'mobx-react'

import { classNames } from 'utils/react'
import { stopPropagation } from 'utils/misc'

import {
  useTranslate, useLocalStore, useCallback, useRef, useAlertController,
} from 'hooks'

import {
  MdFormatBold, MdFormatItalic, MdFormatUnderlined, MdTitle,
  FaListOl, FaListUl, FaQuoteRight, FaLink, FaShareAlt, FaImage, FaEye
} from 'components/shared/icons'
import { LanguageDropdown, OutsideClickHandler } from 'components/shared/inputs'

import Share from './share'

import './toolbar.scss'
import Configuration from "../../../../configuration";

const block = 'edit-toolbar'
const cx = classNames(block)

const useToolbarClick = (toggle : Function, key : string, ...args: any[]) => {
  const clicked = useRef(false)
  const onMouseDown = useCallback((e) => {
    e.preventDefault()
    clicked.current = true
  }, [clicked])
  const onMouseUp = useCallback((e) => {
    e.preventDefault()
    if (clicked.current) {
      clicked.current = false
      toggle(key, ...args)
    }
  }, [clicked, toggle, key, ...args])

  return {
    onMouseDown,
    onMouseUp,
  }
}

const EditToolbar = ({
  toggleMark, toggleBlock, toggleInline,
  isMarkActive, isBlockActive, isInlineActive,
  isInlineAvailable, getInlineData, setInlineData,
  locale, changeLocale, activeLocales,
  article,
} : {
  toggleMark: Function, toggleBlock: Function, toggleInline: Function,
  isMarkActive: Function, isBlockActive: Function, isInlineActive: Function,
  isInlineAvailable: Function, getInlineData: Function, setInlineData: Function,
  locale: string, changeLocale: (locale: string) => void, activeLocales?: string[],
  article?: string,
}) => {
  const Strings = useTranslate('pages.connected.edit.toolbar')

  const linkInputRef = useRef<HTMLInputElement>(null)
  const linkInput = useLocalStore((source) => ({
    show: false,
    toggle() {
      linkInput.show = source.isInlineAvailable('link') ? !linkInput.show : false

      if (linkInput.show) {
        linkInput.focus()
      }
    },
    hide() {
      linkInput.show = false
    },
    focus() {
      requestAnimationFrame(() => {
        if (linkInput.show && linkInputRef.current) {
          linkInputRef.current.focus()
        }
      })
    },
    onChange(e : React.ChangeEvent<HTMLInputElement>) {
      if (e.target.value) {
        if (!source.isInlineActive('link')) {
          source.toggleInline('link', { href: e.target.value })
        } else {
          source.setInlineData({ href: e.target.value })
        }
      } else if (source.isInlineActive('link')) {
        source.toggleInline('link')
      }
    },
  }), {
    toggleInline, isInlineActive, isInlineAvailable, setInlineData,
  })

  const shareModal = useAlertController()

  return (
    <div className={cx(block)}>
      <button
        type="button"
        className={cx('__button')}
        title={Strings.bold}
        {...useToolbarClick(toggleMark, 'bold')}
      >
        <MdFormatBold className={cx('__mark', { '__mark--active': isMarkActive('bold') })} />
      </button>
      <button
        type="button"
        className={cx('__button')}
        title={Strings.italic}
        {...useToolbarClick(toggleMark, 'italic')}
      >
        <MdFormatItalic className={cx('__mark', { '__mark--active': isMarkActive('italic') })} />
      </button>
      <button
        type="button"
        className={cx('__button')}
        title={Strings.underline}
        {...useToolbarClick(toggleMark, 'underline')}
      >
        <MdFormatUnderlined className={cx('__mark', { '__mark--active': isMarkActive('underline') })} />
      </button>

      <div className={cx('__separator')} />

      <button
        type="button"
        className={cx('__button')}
        title={Strings.heading}
        {...useToolbarClick(toggleBlock, 'heading-two')}
      >
        <MdTitle className={cx('__block', { '__block--active': isBlockActive('heading-two') })} />
      </button>
      <button
        type="button"
        className={cx('__button')}
        title={Strings.subheading}
        {...useToolbarClick(toggleBlock, 'subheading')}
      >
        <MdTitle className={cx('__block', '__block--small', { '__block--active': isBlockActive('subheading') })} />
      </button>
      <button
        type="button"
        className={cx('__button')}
        title={Strings.quote}
        {...useToolbarClick(toggleBlock, 'quote-block')}
      >
        <FaQuoteRight className={cx('__block', '__fa', { '__block--active': isBlockActive('quote-block') })} />
      </button>
      <button
        type="button"
        className={cx('__button')}
        title={Strings.list.ordered}
        {...useToolbarClick(toggleBlock, 'list-ordered')}
      >
        <FaListOl className={cx('__block', '__fa', { '__block--active': isBlockActive('list-ordered') })} />
      </button>
      <button
        type="button"
        className={cx('__button')}
        title={Strings.list.unordered}
        {...useToolbarClick(toggleBlock, 'list-unordered')}
      >
        <FaListUl className={cx('__block', '__fa', { '__block--active': isBlockActive('list-unordered') })} />
      </button>

      <div className={cx('__separator')} />

      <button
        type="button"
        className={cx('__button')}
        title={Strings.link}
        {...useToolbarClick(linkInput.toggle, 'link')}
      >
        <FaLink className={cx('__inline', '__fa', { '__block--active': isInlineActive('link') })} />

        <OutsideClickHandler onOutsideClick={linkInput.hide}>
          <input
            ref={linkInputRef}
            onMouseDown={stopPropagation}
            type="text"
            className={cx('__link', { '__link--active': linkInput.show })}
            onChange={linkInput.onChange}
            value={getInlineData('link').href || ''}
          />
        </OutsideClickHandler>
      </button>

      <button
        type="button"
        className={cx('__button')}
        title={Strings.image}
        {...useToolbarClick(toggleBlock, 'image')}
      >
        <FaImage className={cx('__inline', '__fa', { '__block--active': isBlockActive('image') })} />
      </button>

      <div className={cx('__separator')} />

      <div
        className={cx('__button', '__locale')}
        title={Strings.locale}
      >
        <LanguageDropdown onLanguageSelect={changeLocale} language={locale} activeLanguages={activeLocales || []} />
      </div>

      <button type="button" className={cx('__button', '__share')} onClick={shareModal.show}>
        <FaShareAlt className={cx('__fa')} />
      </button>

      <a
        href={`${Configuration.public}/${locale}/preview?article=${article}&locale=${locale}`}
        className={cx('__button', '__share')}
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaEye className={cx('__fa')} />
      </a>

      <Share alertState={shareModal} article={article} />
    </div>
  )
}

export default observer(EditToolbar)
